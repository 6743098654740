/*顶部工具条背景颜色*/
.w-e-bar {
  background-color: var(--bs-body-bg) !important;
}


.w-e-bar-item .disabled {
  color: var(--bs-tertiary-color) !important;
}

.w-e-bar-item .disabled svg {
  fill: var(--bs-tertiary-color) !important;
}


/*在编辑器中选中文字后的颜色*/
.w-e-hover-bar {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*这个是删除线-行内代码-上标-下表-清除格式*/
.w-e-bar-item-group .w-e-bar-item-menus-container {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*颜色下拉菜单*/
.w-e-drop-panel {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.w-e-panel-content-color li .color-block {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.w-e-panel-content-color li {
  border: var(--bs-border-width) solid var(--bs-body-bg) !important;
}

.w-e-panel-content-color li:hover {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}


/*修改每个按钮的颜色*/
.w-e-bar-item button {
  color: var(--bs-body-color) !important;
}

/*鼠标滑过时的颜色*/
.w-e-bar-item button:hover {
  background-color: rgba(var(--bs-tertiary-color-rgb), .08) !important;
}

/*工具条上的项目被激活时的背景色*/
.w-e-bar-item .active {
  background-color: var(--bs-secondary-bg) !important;
}

/*表格*/
.w-e-panel-content-table {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
}

/*表格里面每个td*/
.w-e-panel-content-table td {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}


/*修改每个svg的言饿死*/
.w-e-bar svg {
  fill: var(--bs-body-color) !important;
}

/*修改分割线的颜色*/
.w-e-bar-divider {
  background-color: var(--bs-secondary-bg) !important;
}

/*背景色*/
.w-e-select-list {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*被选中的*/
.w-e-select-list ul .selected {
  background-color: var(--bs-secondary-bg) !important;

}

/*鼠标移过*/
.w-e-select-list ul li:hover {
  background-color: rgba(var(--bs-tertiary-color-rgb), .08) !important;

}

.w-e-select-list ul li {
  color: var(--bs-body-color) !important;
}


.w-e-text-container [data-slate-editor] {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
}

/*链接、图片、视频*/
.w-e-modal {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}

/*链接、图片、视频里面的输入框*/
.w-e-modal input[type=number], .w-e-modal input[type=text], .w-e-modal textarea {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}


.w-e-text-container {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-top: none !important;
}

.w-e-toolbar {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*提示框保持和bootstrap风格主题一致*/
.w-e-menu-tooltip-v5:before {
  background-color: var(--bs-emphasis-color) !important;
  color: var(--bs-body-bg) !important;
  opacity: 0.9 !important;
}

/*编辑内容时表格外层包裹的的一个大容器*/
.w-e-text-container [data-slate-editor] [data-selected=true] {
  box-shadow: 0 0 0 2px var(--bs-border-color) !important;
}

/*表格的包裹虚线边框*/
.w-e-text-container [data-slate-editor] .table-container {
  border: 1px dashed var(--bs-border-color) !important;
}

/*编辑区域表格的表头*/
.w-e-text-container [data-slate-editor] table th {
  background-color: var(--bs-secondary-bg) !important;
}

/*编辑区域表格的td，th所有的边框*/
.w-e-text-container [data-slate-editor] table td,
.w-e-text-container [data-slate-editor] table th {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*代码区域的背景色*/
.w-e-text-container [data-slate-editor] pre > code {
  background-color: var(--bs-secondary-bg) !important;;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  text-shadow: 0 1px var(--bs-body-color) !important;
  color: var(--bs-body-color) !important;
}


/*工具栏表情选择的配色*/
.w-e-panel-content-emotion li:hover{
  background-color: rgba(var(--bs-tertiary-color-rgb), .08) !important;
}

/*模态框的按钮背景色*/
.w-e-modal button{
  background-color: var(--bs-tertiary-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}

/*placehoder提示信息的颜色*/
.w-e-max-length-info, .w-e-text-placeholder {
  color: var(--bs-secondary-color) !important;
}


//解决和bootstrap的.btn-close冲突问题
.w-e-modal .btn-close{
  background: unset !important;
}


//容器的背景色，滚动时底部有个背景色
.w-e-text-container{
  background-color: var(--bs-body-bg) !important;
}

//编辑区域的分割线
.w-e-textarea-divider hr {
  background-color: var(--bs-secondary-color) !important;
}