.tempus-dominus-widget.light {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
}


.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).old, .tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).new, .tempus-dominus-widget.light .date-container-years div:not(.no-highlight).old, .tempus-dominus-widget.light .date-container-years div:not(.no-highlight).new, .tempus-dominus-widget.light .date-container-months div:not(.no-highlight).old, .tempus-dominus-widget.light .date-container-months div:not(.no-highlight).new, .tempus-dominus-widget.light .date-container-days div:not(.no-highlight).old, .tempus-dominus-widget.light .date-container-days div:not(.no-highlight).new, .tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).old, .tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).new, .tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).old, .tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).new, .tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).old, .tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).new, .tempus-dominus-widget.light .time-container-second div:not(.no-highlight).old, .tempus-dominus-widget.light .time-container-second div:not(.no-highlight).new {
  color: var(--bs-secondary-color);
}

.tempus-dominus-widget.light .date-container-days .dow{
  color: var(--bs-secondary-color);
}


.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight):hover,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight):hover,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight):hover,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight):hover {
  background: var(--bs-secondary-bg);
}

.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).active,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight).active,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight).active,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).active,
.tempus-dominus-widget.light .date-container-days div.range-in:not(.no-highlight),
.tempus-dominus-widget.light .date-container-days div.range-end:not(.no-highlight),
.tempus-dominus-widget.light .date-container-days div.range-start:not(.no-highlight),
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight).active {
  background-color: #0d6efd;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.tempus-dominus-widget.light .toolbar div:hover {
  background: var(--bs-secondary-bg);
}