.quicktab {
  > ul {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }

  div.flex-grow-1 {
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
  }
  .nav {
    --bs-nav-link-color: var(--bs-body-color);
    --bs-nav-link-hover-color: var(--bs-body-color);
    --bs-nav-link-disabled-color: var(--bs-secondary-color);
  }

  .nav-pills {
    --bs-nav-pills-link-active-color: #0d6efd;
    --bs-nav-pills-link-active-bg: rgb(13 110 253 / 12%);
  }
}

