.swal2-popup {
  background-color: var(--bs-body-bg) !important;
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  background-color: var(--bs-body-bg) !important;
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right]{
  background-color: var(--bs-body-bg) !important;
}

.swal2-icon.swal2-success .swal2-success-fix{
  background-color: var(--bs-body-bg) !important;
}


//标题
.swal2-title{
  color: var(--bs-body-color) !important;
}

//内容
.swal2-html-container{
  color: var(--bs-secondary-color) !important;
}

//进度条
.swal2-timer-progress-bar{
  background-color: var(--bs-secondary-bg) !important;
}