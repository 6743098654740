@mixin quicktab-nav-pill{
  // bootstrap-quicktab
  .quicktab {
    .nav-pills {
      --bs-nav-pills-link-active-color: var(--bs-light-text);
      --bs-nav-pills-link-active-bg: var(--bs-light-bg-subtle);
    }
  }
}

