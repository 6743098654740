.select2-container--bootstrap-5 .select2-selection {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered {
  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-selection--multiple .select2-search .select2-search__field {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-selection--multiple .select2-search .select2-search__field::placeholder {

  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}


.select2-container--bootstrap-5 .select2-dropdown {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}

/*鼠标移入到每个li上面的样式*/
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options
.select2-results__option.select2-results__option--highlighted {
  background-color: var(--bs-tertiary-bg) !important;
  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  color: var(--bs-body-color) !important;
}


.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected,
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted) {
  background-color: var(--bs-secondary-bg) !important;
  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__message{
  color: var(--bs-body-color) !important;
}