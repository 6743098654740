.bsa-gallery-grid {
  column-count: 4;
  column-gap: 12px;
  column-fill: balance;

  @include media-breakpoint-down(md) {
    column-count: 2;
  }

  @include media-breakpoint-down(sm) {
    column-count: 1;
  }

  .bsa-gallery-item {
    padding-bottom: 12px;
    break-inside: avoid;
  }

  .card {
    transition: all .2s;
    @include media-breakpoint-up(lg) {
      &:hover {
        box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, .3) !important;
      }
    }


  }

}

