// 官方默认黑色主题适配
@include color-mode("dark", true) {
  .dropdown-menu {
    // --#{$prefix}-dropdown-bg: #{mix($blue-500, $blue-600)};
    --#{$prefix}dropdown-link-active-bg: #{$gray-700};
  }

  @include quicktab-nav-pill();
  @include sidebar-menu();
}


@include color-mode("indigo", true) {

  // 这里是表单触发背景阴影
  .form-control:focus {
    border-color: $body-color-indigo;
    box-shadow: 0 0 0 .25rem rgba($body-color-indigo, .25);
  }


  // 多选框和单选框
  .form-check-input:focus {
    border-color: $body-color-indigo;
    box-shadow: 0 0 0 .25rem rgba($body-color-indigo, .25);
  }

  .form-check-input:checked {
    background-color: $body-color-indigo;
    border-color: $body-color-indigo;
  }

  // 手风琴
  .accordion {
    --bs-accordion-active-bg: #{rgba($body-color-indigo, .15)};
    --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem rgb(148 62 255 / 25%);
  }


  // 下拉选择框
  .form-select:focus {
    border-color: $body-color-indigo;
    box-shadow: 0 0 0 .25rem rgba($body-color-indigo, .25);
  }


  .dropdown-menu {
    // --#{$prefix}-dropdown-bg: #{mix($blue-500, $blue-600)};
    --#{$prefix}dropdown-link-active-bg: #{$indigo-700};
  }


  @include quicktab-nav-pill();
  @include sidebar-menu();

}


@include color-mode("green", true) {
  .dropdown-menu {
    --#{$prefix}dropdown-link-active-bg: #{$green-700};
  }

  @include quicktab-nav-pill();
  @include sidebar-menu();
}

@include color-mode("blue", true) {
  .dropdown-menu {
    --#{$prefix}dropdown-link-active-bg: #{$blue-700};
  }

  @include quicktab-nav-pill();
  @include sidebar-menu();
}

@include color-mode("yellow", true) {
  .dropdown-menu {
    --#{$prefix}dropdown-link-active-bg: #{$yellow-700};
  }

  @include quicktab-nav-pill();
  @include sidebar-menu();
}

@include color-mode("pink", true) {
  .dropdown-menu {
    --#{$prefix}dropdown-link-active-bg: #{$pink-700};
  }

  @include quicktab-nav-pill();
  @include sidebar-menu();
}

@include color-mode("red", true) {
  .dropdown-menu {
    --#{$prefix}dropdown-link-active-bg: #{$red-700};
  }

  @include quicktab-nav-pill();
  @include sidebar-menu();
}

@include color-mode("teal", true) {
  .dropdown-menu {
    --#{$prefix}dropdown-link-active-bg: #{$teal-700};
  }

  @include quicktab-nav-pill();
  @include sidebar-menu();
}

@include color-mode("orange", true) {
  .dropdown-menu {
    --#{$prefix}dropdown-link-active-bg: #{$orange-700};
  }

  @include quicktab-nav-pill();
  @include sidebar-menu();
}

@include color-mode("cyan", true) {
  .dropdown-menu {
    --#{$prefix}dropdown-link-active-bg: #{$cyan-700};
  }

  @include quicktab-nav-pill();
  @include sidebar-menu();
}
