// 遮罩层在lg下才生效
@include media-breakpoint-down(lg) {
  .bsa-mask {
    z-index: 90;
    position: fixed;
    inset: 0;
    transition: all .2s ease-out;
    background-color: rgb(0 0 0 / 60%);
    cursor: move;
  }
}
