@charset "UTF-8";
:root {
  scroll-behavior: auto !important;
}

:root,
[data-bs-theme=light] {
  --bsa-header-height: 60px;
  --bsa-sidebar-width: 240px;
  --bsa-footer-height: 38px;
}

table {
  white-space: nowrap;
}

* {
  scrollbar-color: rgba(var(--bs-tertiary-color-rgb), 0.18) var(--bs-light-bg-subtle);
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(var(--bs-tertiary-color-rgb), 0.18);
}

::-webkit-scrollbar-track {
  background: var(--bs-light-bg-subtle);
}

::-webkit-scrollbar-corner {
  background-color: var(--bs-light-bg-subtle);
}

[data-bs-theme=indigo] {
  --bs-body-color: #6610f2;
  --bs-body-color-rgb: 102, 16, 242;
  --bs-body-bg: #140330;
  --bs-body-bg-rgb: 20, 3, 48;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(102, 16, 242, 0.75);
  --bs-secondary-color-rgb: 102, 16, 242;
  --bs-secondary-bg: #290661;
  --bs-secondary-bg-rgb: 41, 6, 97;
  --bs-tertiary-color: rgba(102, 16, 242, 0.5);
  --bs-tertiary-color-rgb: 102, 16, 242;
  --bs-tertiary-bg: #1f0549;
  --bs-tertiary-bg-rgb: 31, 5, 73;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #e0cffc;
  --bs-dark-text-emphasis: #a370f7;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #290661;
  --bs-dark-bg-subtle: #150331;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #3d0a91;
  --bs-dark-border-subtle: #290661;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #3d0a91;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

[data-bs-theme=green] {
  --bs-body-color: #198754;
  --bs-body-color-rgb: 25, 135, 84;
  --bs-body-bg: #051b11;
  --bs-body-bg-rgb: 5, 27, 17;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(25, 135, 84, 0.75);
  --bs-secondary-color-rgb: 25, 135, 84;
  --bs-secondary-bg: #0a3622;
  --bs-secondary-bg-rgb: 10, 54, 34;
  --bs-tertiary-color: rgba(25, 135, 84, 0.5);
  --bs-tertiary-color-rgb: 25, 135, 84;
  --bs-tertiary-bg: #08291a;
  --bs-tertiary-bg-rgb: 8, 41, 26;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #d1e7dd;
  --bs-dark-text-emphasis: #75b798;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #0a3622;
  --bs-dark-bg-subtle: #051b11;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #0f5132;
  --bs-dark-border-subtle: #0a3622;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #0f5132;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

[data-bs-theme=pink] {
  --bs-body-color: #d63384;
  --bs-body-color-rgb: 214, 51, 132;
  --bs-body-bg: #2b0a1a;
  --bs-body-bg-rgb: 43, 10, 26;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(214, 51, 132, 0.75);
  --bs-secondary-color-rgb: 214, 51, 132;
  --bs-secondary-bg: #561435;
  --bs-secondary-bg-rgb: 86, 20, 53;
  --bs-tertiary-color: rgba(214, 51, 132, 0.5);
  --bs-tertiary-color-rgb: 214, 51, 132;
  --bs-tertiary-bg: #410f28;
  --bs-tertiary-bg-rgb: 65, 15, 40;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f7d6e6;
  --bs-dark-text-emphasis: #e685b5;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #561435;
  --bs-dark-bg-subtle: #2b0a1b;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #801f4f;
  --bs-dark-border-subtle: #561435;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #801f4f;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

[data-bs-theme=yellow] {
  --bs-body-color: #ffc107;
  --bs-body-color-rgb: 255, 193, 7;
  --bs-body-bg: #332701;
  --bs-body-bg-rgb: 51, 39, 1;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(255, 193, 7, 0.75);
  --bs-secondary-color-rgb: 255, 193, 7;
  --bs-secondary-bg: #664d03;
  --bs-secondary-bg-rgb: 102, 77, 3;
  --bs-tertiary-color: rgba(255, 193, 7, 0.5);
  --bs-tertiary-color-rgb: 255, 193, 7;
  --bs-tertiary-bg: #4d3a02;
  --bs-tertiary-bg-rgb: 77, 58, 2;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #fff3cd;
  --bs-dark-text-emphasis: #ffda6a;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #664d03;
  --bs-dark-bg-subtle: #332702;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #997404;
  --bs-dark-border-subtle: #664d03;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #997404;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

[data-bs-theme=blue] {
  --bs-body-color: #0d6efd;
  --bs-body-color-rgb: 13, 110, 253;
  --bs-body-bg: #031633;
  --bs-body-bg-rgb: 3, 22, 51;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(13, 110, 253, 0.75);
  --bs-secondary-color-rgb: 13, 110, 253;
  --bs-secondary-bg: #052c65;
  --bs-secondary-bg-rgb: 5, 44, 101;
  --bs-tertiary-color: rgba(13, 110, 253, 0.5);
  --bs-tertiary-color-rgb: 13, 110, 253;
  --bs-tertiary-bg: #04214c;
  --bs-tertiary-bg-rgb: 4, 33, 76;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #cfe2ff;
  --bs-dark-text-emphasis: #6ea8fe;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #052c65;
  --bs-dark-bg-subtle: #031633;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #084298;
  --bs-dark-border-subtle: #052c65;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #084298;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

[data-bs-theme=red] {
  --bs-body-color: #dc3545;
  --bs-body-color-rgb: 220, 53, 69;
  --bs-body-bg: #2c0b0e;
  --bs-body-bg-rgb: 44, 11, 14;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(220, 53, 69, 0.75);
  --bs-secondary-color-rgb: 220, 53, 69;
  --bs-secondary-bg: #58151c;
  --bs-secondary-bg-rgb: 88, 21, 28;
  --bs-tertiary-color: rgba(220, 53, 69, 0.5);
  --bs-tertiary-color-rgb: 220, 53, 69;
  --bs-tertiary-bg: #421015;
  --bs-tertiary-bg-rgb: 66, 16, 21;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8d7da;
  --bs-dark-text-emphasis: #ea868f;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #58151c;
  --bs-dark-bg-subtle: #2c0b0e;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #842029;
  --bs-dark-border-subtle: #58151c;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #842029;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

[data-bs-theme=orange] {
  --bs-body-color: #fd7e14;
  --bs-body-color-rgb: 253, 126, 20;
  --bs-body-bg: #331904;
  --bs-body-bg-rgb: 51, 25, 4;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(253, 126, 20, 0.75);
  --bs-secondary-color-rgb: 253, 126, 20;
  --bs-secondary-bg: #653208;
  --bs-secondary-bg-rgb: 101, 50, 8;
  --bs-tertiary-color: rgba(253, 126, 20, 0.5);
  --bs-tertiary-color-rgb: 253, 126, 20;
  --bs-tertiary-bg: #4c2606;
  --bs-tertiary-bg-rgb: 76, 38, 6;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #ffe5d0;
  --bs-dark-text-emphasis: #feb272;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #653208;
  --bs-dark-bg-subtle: #331904;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #984c0c;
  --bs-dark-border-subtle: #653208;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #984c0c;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

[data-bs-theme=teal] {
  --bs-body-color: #20c997;
  --bs-body-color-rgb: 32, 201, 151;
  --bs-body-bg: #06281e;
  --bs-body-bg-rgb: 6, 40, 30;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(32, 201, 151, 0.75);
  --bs-secondary-color-rgb: 32, 201, 151;
  --bs-secondary-bg: #0d503c;
  --bs-secondary-bg-rgb: 13, 80, 60;
  --bs-tertiary-color: rgba(32, 201, 151, 0.5);
  --bs-tertiary-color-rgb: 32, 201, 151;
  --bs-tertiary-bg: #0a3c2d;
  --bs-tertiary-bg-rgb: 10, 60, 45;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #d2f4ea;
  --bs-dark-text-emphasis: #79dfc1;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #0d503c;
  --bs-dark-bg-subtle: #07281e;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #13795b;
  --bs-dark-border-subtle: #0d503c;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #13795b;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

[data-bs-theme=cyan] {
  --bs-body-color: #0dcaf0;
  --bs-body-color-rgb: 13, 202, 240;
  --bs-body-bg: #032830;
  --bs-body-bg-rgb: 3, 40, 48;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(13, 202, 240, 0.75);
  --bs-secondary-color-rgb: 13, 202, 240;
  --bs-secondary-bg: #055160;
  --bs-secondary-bg-rgb: 5, 81, 96;
  --bs-tertiary-color: rgba(13, 202, 240, 0.5);
  --bs-tertiary-color-rgb: 13, 202, 240;
  --bs-tertiary-bg: #043d48;
  --bs-tertiary-bg-rgb: 4, 61, 72;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #cff4fc;
  --bs-dark-text-emphasis: #6edff6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #055160;
  --bs-dark-bg-subtle: #032930;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #087990;
  --bs-dark-border-subtle: #055160;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #087990;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

[data-bs-theme=dark] .dropdown-menu {
  --bs-dropdown-link-active-bg: #495057;
}
[data-bs-theme=dark] .quicktab .nav-pills {
  --bs-nav-pills-link-active-color: var(--bs-light-text);
  --bs-nav-pills-link-active-bg: var(--bs-light-bg-subtle);
}
[data-bs-theme=dark] .bsa-menu li a + ul {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08);
}
[data-bs-theme=dark] .bsa-menu li a.active, [data-bs-theme=dark] .bsa-menu li a:active, [data-bs-theme=dark] .bsa-menu li a:focus, [data-bs-theme=dark] .bsa-menu li a:hover {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}
[data-bs-theme=dark] .bsa-menu li a.has-children.open {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}

[data-bs-theme=indigo] .form-control:focus {
  border-color: #6610f2;
  box-shadow: 0 0 0 0.25rem rgba(102, 16, 242, 0.25);
}
[data-bs-theme=indigo] .form-check-input:focus {
  border-color: #6610f2;
  box-shadow: 0 0 0 0.25rem rgba(102, 16, 242, 0.25);
}
[data-bs-theme=indigo] .form-check-input:checked {
  background-color: #6610f2;
  border-color: #6610f2;
}
[data-bs-theme=indigo] .accordion {
  --bs-accordion-active-bg: rgba(102, 16, 242, 0.15);
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem rgb(148 62 255 / 25%);
}
[data-bs-theme=indigo] .form-select:focus {
  border-color: #6610f2;
  box-shadow: 0 0 0 0.25rem rgba(102, 16, 242, 0.25);
}
[data-bs-theme=indigo] .dropdown-menu {
  --bs-dropdown-link-active-bg: #3d0a91;
}
[data-bs-theme=indigo] .quicktab .nav-pills {
  --bs-nav-pills-link-active-color: var(--bs-light-text);
  --bs-nav-pills-link-active-bg: var(--bs-light-bg-subtle);
}
[data-bs-theme=indigo] .bsa-menu li a + ul {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08);
}
[data-bs-theme=indigo] .bsa-menu li a.active, [data-bs-theme=indigo] .bsa-menu li a:active, [data-bs-theme=indigo] .bsa-menu li a:focus, [data-bs-theme=indigo] .bsa-menu li a:hover {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}
[data-bs-theme=indigo] .bsa-menu li a.has-children.open {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}

[data-bs-theme=green] .dropdown-menu {
  --bs-dropdown-link-active-bg: #0f5132;
}
[data-bs-theme=green] .quicktab .nav-pills {
  --bs-nav-pills-link-active-color: var(--bs-light-text);
  --bs-nav-pills-link-active-bg: var(--bs-light-bg-subtle);
}
[data-bs-theme=green] .bsa-menu li a + ul {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08);
}
[data-bs-theme=green] .bsa-menu li a.active, [data-bs-theme=green] .bsa-menu li a:active, [data-bs-theme=green] .bsa-menu li a:focus, [data-bs-theme=green] .bsa-menu li a:hover {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}
[data-bs-theme=green] .bsa-menu li a.has-children.open {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}

[data-bs-theme=blue] .dropdown-menu {
  --bs-dropdown-link-active-bg: #084298;
}
[data-bs-theme=blue] .quicktab .nav-pills {
  --bs-nav-pills-link-active-color: var(--bs-light-text);
  --bs-nav-pills-link-active-bg: var(--bs-light-bg-subtle);
}
[data-bs-theme=blue] .bsa-menu li a + ul {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08);
}
[data-bs-theme=blue] .bsa-menu li a.active, [data-bs-theme=blue] .bsa-menu li a:active, [data-bs-theme=blue] .bsa-menu li a:focus, [data-bs-theme=blue] .bsa-menu li a:hover {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}
[data-bs-theme=blue] .bsa-menu li a.has-children.open {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}

[data-bs-theme=yellow] .dropdown-menu {
  --bs-dropdown-link-active-bg: #997404;
}
[data-bs-theme=yellow] .quicktab .nav-pills {
  --bs-nav-pills-link-active-color: var(--bs-light-text);
  --bs-nav-pills-link-active-bg: var(--bs-light-bg-subtle);
}
[data-bs-theme=yellow] .bsa-menu li a + ul {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08);
}
[data-bs-theme=yellow] .bsa-menu li a.active, [data-bs-theme=yellow] .bsa-menu li a:active, [data-bs-theme=yellow] .bsa-menu li a:focus, [data-bs-theme=yellow] .bsa-menu li a:hover {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}
[data-bs-theme=yellow] .bsa-menu li a.has-children.open {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}

[data-bs-theme=pink] .dropdown-menu {
  --bs-dropdown-link-active-bg: #801f4f;
}
[data-bs-theme=pink] .quicktab .nav-pills {
  --bs-nav-pills-link-active-color: var(--bs-light-text);
  --bs-nav-pills-link-active-bg: var(--bs-light-bg-subtle);
}
[data-bs-theme=pink] .bsa-menu li a + ul {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08);
}
[data-bs-theme=pink] .bsa-menu li a.active, [data-bs-theme=pink] .bsa-menu li a:active, [data-bs-theme=pink] .bsa-menu li a:focus, [data-bs-theme=pink] .bsa-menu li a:hover {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}
[data-bs-theme=pink] .bsa-menu li a.has-children.open {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}

[data-bs-theme=red] .dropdown-menu {
  --bs-dropdown-link-active-bg: #842029;
}
[data-bs-theme=red] .quicktab .nav-pills {
  --bs-nav-pills-link-active-color: var(--bs-light-text);
  --bs-nav-pills-link-active-bg: var(--bs-light-bg-subtle);
}
[data-bs-theme=red] .bsa-menu li a + ul {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08);
}
[data-bs-theme=red] .bsa-menu li a.active, [data-bs-theme=red] .bsa-menu li a:active, [data-bs-theme=red] .bsa-menu li a:focus, [data-bs-theme=red] .bsa-menu li a:hover {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}
[data-bs-theme=red] .bsa-menu li a.has-children.open {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}

[data-bs-theme=teal] .dropdown-menu {
  --bs-dropdown-link-active-bg: #13795b;
}
[data-bs-theme=teal] .quicktab .nav-pills {
  --bs-nav-pills-link-active-color: var(--bs-light-text);
  --bs-nav-pills-link-active-bg: var(--bs-light-bg-subtle);
}
[data-bs-theme=teal] .bsa-menu li a + ul {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08);
}
[data-bs-theme=teal] .bsa-menu li a.active, [data-bs-theme=teal] .bsa-menu li a:active, [data-bs-theme=teal] .bsa-menu li a:focus, [data-bs-theme=teal] .bsa-menu li a:hover {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}
[data-bs-theme=teal] .bsa-menu li a.has-children.open {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}

[data-bs-theme=orange] .dropdown-menu {
  --bs-dropdown-link-active-bg: #984c0c;
}
[data-bs-theme=orange] .quicktab .nav-pills {
  --bs-nav-pills-link-active-color: var(--bs-light-text);
  --bs-nav-pills-link-active-bg: var(--bs-light-bg-subtle);
}
[data-bs-theme=orange] .bsa-menu li a + ul {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08);
}
[data-bs-theme=orange] .bsa-menu li a.active, [data-bs-theme=orange] .bsa-menu li a:active, [data-bs-theme=orange] .bsa-menu li a:focus, [data-bs-theme=orange] .bsa-menu li a:hover {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}
[data-bs-theme=orange] .bsa-menu li a.has-children.open {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}

[data-bs-theme=cyan] .dropdown-menu {
  --bs-dropdown-link-active-bg: #087990;
}
[data-bs-theme=cyan] .quicktab .nav-pills {
  --bs-nav-pills-link-active-color: var(--bs-light-text);
  --bs-nav-pills-link-active-bg: var(--bs-light-bg-subtle);
}
[data-bs-theme=cyan] .bsa-menu li a + ul {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08);
}
[data-bs-theme=cyan] .bsa-menu li a.active, [data-bs-theme=cyan] .bsa-menu li a:active, [data-bs-theme=cyan] .bsa-menu li a:focus, [data-bs-theme=cyan] .bsa-menu li a:hover {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}
[data-bs-theme=cyan] .bsa-menu li a.has-children.open {
  color: unset;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18);
}

.bsa-loading {
  z-index: 9999999999;
  position: fixed;
  inset: 0;
  transition: all 0.2s ease-out;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.bsa-modal .iframe-wrapper {
  height: calc(100vh - 12rem) !important;
}
.bsa-modal .modal-header i:hover {
  color: var(--bs-tertiary-color);
}
.bsa-modal .modal-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.bsa-modal .modal-dialog.modal-fullscreen .iframe-wrapper {
  height: 100% !important;
}
@media (max-width: 575.98px) {
  .bsa-modal .modal-dialog.modal-fullscreen-sm-down .iframe-wrapper {
    height: 100% !important;
  }
}
@media (max-width: 575.98px) {
  .bsa-modal .modal-dialog.modal-fullscreen-sm-down .btn-fullscreen {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .bsa-modal .modal-dialog.modal-fullscreen-md-down .iframe-wrapper {
    height: 100% !important;
  }
}
@media (max-width: 767.98px) {
  .bsa-modal .modal-dialog.modal-fullscreen-md-down .btn-fullscreen {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .bsa-modal .modal-dialog.modal-fullscreen-lg-down .iframe-wrapper {
    height: 100% !important;
  }
}
@media (max-width: 991.98px) {
  .bsa-modal .modal-dialog.modal-fullscreen-lg-down .btn-fullscreen {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .bsa-modal .modal-dialog.modal-fullscreen-xl-down .iframe-wrapper {
    height: 100% !important;
  }
}
@media (max-width: 1199.98px) {
  .bsa-modal .modal-dialog.modal-fullscreen-xl-down .btn-fullscreen {
    display: none !important;
  }
}
@media (max-width: 1399.98px) {
  .bsa-modal .modal-dialog.modal-fullscreen-xxl-down .iframe-wrapper {
    height: 100% !important;
  }
}
@media (max-width: 1399.98px) {
  .bsa-modal .modal-dialog.modal-fullscreen-xxl-down .btn-fullscreen {
    display: none !important;
  }
}

@keyframes progress {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}
.bsa-toast-pause .progress-bar {
  animation-play-state: paused !important;
}

.quicktab > ul {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.quicktab div.flex-grow-1 {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
}
.quicktab .nav {
  --bs-nav-link-color: var(--bs-body-color);
  --bs-nav-link-hover-color: var(--bs-body-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
}
.quicktab .nav-pills {
  --bs-nav-pills-link-active-color: #0d6efd;
  --bs-nav-pills-link-active-bg: rgb(13 110 253 / 12%);
}

.dropzone {
  border: none !important;
  background: var(--bs-light-bg-subtle) !important;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /*默认提示文本*/
}
.dropzone .dz-default {
  color: var(--bs-light-text) !important;
}
.dropzone .dz-preview {
  background: var(--bs-light-bg-subtle) !important;
}
.dropzone .dz-preview .dz-image {
  background-color: rgba(0, 0, 0, 0.08);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.dropzone .dz-preview .dz-error-message {
  margin-top: 1.3rem;
  background: #dc3545 !important;
}
.dropzone .dz-preview .dz-error-message:after {
  border-bottom-color: #dc3545 !important;
}
.dropzone .dz-preview .dz-remove {
  text-decoration: none !important;
}

.bootstrap-table .fixed-table-container .fixed-table-body .fixed-table-loading {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bootstrap-table .fixed-table-container.fixed-height .fixed-table-border {
  border-left: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-right: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.bootstrap-table .fixed-table-container.fixed-height:not(.has-footer) {
  border-bottom: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.bootstrap-table .fixed-table-container.fixed-height .table thead th {
  border-bottom: none !important;
}

.bootstrap-table .fixed-table-container .fixed-table-body .fixed-table-loading .loading-wrap .animation-dot,
.bootstrap-table .fixed-table-container .fixed-table-body .fixed-table-loading .loading-wrap .animation-wrap::after,
.bootstrap-table .fixed-table-container .fixed-table-body .fixed-table-loading .loading-wrap .animation-wrap::before {
  background-color: var(--bs-light-text) !important;
}

.bootstrap-table .fixed-table-container .fixed-table-body .fixed-table-loading .loading-wrap .loading-text {
  color: var(--bs-light-text) !important;
}

.bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox] {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}
.bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox][type=checkbox] {
  border-radius: 0.25em;
}
.bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox][type=radio] {
  border-radius: 50%;
}
.bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox]:active {
  filter: brightness(90%);
}
.bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox]:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox]:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox]:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox]:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox][type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox]:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox][disabled] ~ .form-check-label, .bootstrap-table .fixed-table-container .table .bs-checkbox label input[type=checkbox]:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox] {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}
.bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox][type=checkbox] {
  border-radius: 0.25em;
}
.bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox][type=radio] {
  border-radius: 50%;
}
.bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox]:active {
  filter: brightness(90%);
}
.bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox]:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox]:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox]:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox]:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox][type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox]:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox][disabled] ~ .form-check-label, .bootstrap-table .keep-open .dropdown-item-marker input[type=checkbox]:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.bootstrap-table .card-views .card-view label input[type=checkbox] {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact;
}
.bootstrap-table .card-views .card-view label input[type=checkbox][type=checkbox] {
  border-radius: 0.25em;
}
.bootstrap-table .card-views .card-view label input[type=checkbox][type=radio] {
  border-radius: 50%;
}
.bootstrap-table .card-views .card-view label input[type=checkbox]:active {
  filter: brightness(90%);
}
.bootstrap-table .card-views .card-view label input[type=checkbox]:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.bootstrap-table .card-views .card-view label input[type=checkbox]:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.bootstrap-table .card-views .card-view label input[type=checkbox]:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.bootstrap-table .card-views .card-view label input[type=checkbox]:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.bootstrap-table .card-views .card-view label input[type=checkbox][type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.bootstrap-table .card-views .card-view label input[type=checkbox]:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.bootstrap-table .card-views .card-view label input[type=checkbox][disabled] ~ .form-check-label, .bootstrap-table .card-views .card-view label input[type=checkbox]:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.bootstrap-table.fullscreen {
  background: var(--bs-body-bg) !important;
  padding: 12px !important;
}

.bootstrap-table .pagination {
  flex-wrap: wrap !important;
}

.fixed-columns, .fixed-columns-right {
  background-color: var(--bs-body-bg);
}

.icons-selector.fip-bootstrap .selector-button {
  background-color: var(--bs-tertiary-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  background-image: none !important;
}

.icons-selector.fip-bootstrap .selected-icon {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-right: none !important;
}

.icons-selector.fip-bootstrap .selected-icon i {
  color: var(--bs-body-color) !important;
}

.icons-selector.fip-bootstrap span.current-icon,
.icons-selector.fip-bootstrap span.current-icon:hover {
  color: var(--bs-light-text-emphasis) !important;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.1) !important;
  border: var(--bs-border-width) solid var(--bs-light-border-subtle) !important;
}

.icons-selector.fip-bootstrap .selector-popup {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
}

.icons-selector.fip-bootstrap .selector-category select,
.icons-selector.fip-bootstrap .selector-search input[type=text] {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}

.icons-selector.fip-bootstrap .fip-box:hover {
  box-shadow: none !important;
}

.icons-selector.fip-bootstrap .selector-category select:focus, .icons-selector.fip-bootstrap .selector-search input[type=text]:focus {
  box-shadow: none !important;
}

.icons-selector.fip-bootstrap .selector-search input[type=text]::-webkit-input-placeholder {
  color: var(--bs-body-color) !important;
}

.icons-selector.fip-bootstrap .selector-search i {
  color: var(--bs-body-color) !important;
}

.icons-selector.fip-bootstrap .fip-icons-container {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.icons-selector.fip-bootstrap .fip-box {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.icons-selector.fip-bootstrap .fip-box:hover {
  background-color: var(--bs-body-bg) !important;
}

.icons-selector.fip-bootstrap .selector-button:hover {
  background-color: var(--bs-body-bg) !important;
}

.icons-selector.fip-bootstrap .selector-footer,
.icons-selector.fip-bootstrap .selector-footer i {
  color: var(--bs-body-color) !important;
}

.icons-selector.fip-bootstrap span.current-icon:hover i, .icons-selector.fip-bootstrap span.current-icon i {
  color: var(--bs-light-text-emphasis) !important;
}

.select2-container--bootstrap-5 .select2-selection {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered {
  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-selection--multiple .select2-search .select2-search__field {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-selection--multiple .select2-search .select2-search__field::placeholder {
  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-dropdown {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}

/*鼠标移入到每个li上面的样式*/
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--highlighted {
  background-color: var(--bs-tertiary-bg) !important;
  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected,
.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted) {
  background-color: var(--bs-secondary-bg) !important;
  color: var(--bs-body-color) !important;
}

.select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__message {
  color: var(--bs-body-color) !important;
}

.ztree * {
  font-size: 16px;
}

.ztree {
  color: var(--bs-body-color);
}

.ztree li a {
  height: 21px;
  color: var(--bs-body-color);
}

.ztree li a.curSelectedNode {
  color: var(--bs-dark-text-emphasis);
  background-color: var(--bs-dark-bg-subtle);
  border: 1px solid var(--bs-dark-border-subtle);
  height: 20px;
}

.ztree li a.curSelectedNode_Edit {
  height: 20px;
}

.ztree li a.tmpTargetNode_inner {
  background-color: var(--bs-secondary-bg-subtle);
  color: var(--bs-secondary-text-emphasis);
  border: 1px solid var(--bs-secondary-border-subtle);
  height: 21px;
}

.ztree li a input.rename {
  border: none;
  outline: none;
  width: 120px;
  font-size: 14px;
  background-color: var(--bs-secondary-bg-subtle);
  color: var(--bs-secondary-text-emphasis);
}

.ztree li ul.line {
  background: url(../img/line_conn.png) 0 0 repeat-y !important;
}

.ztree li span {
  line-height: 17px;
}

.ztree li span.button {
  width: 21px;
  height: 21px;
  background-image: url("../img/bootstrap.png") !important;
}

.ztree li span.button.chk.checkbox_false_full {
  background-position: -5px -5px;
}

.ztree li span.button.chk.checkbox_false_full_focus {
  background-position: -5px -26px;
}

.ztree li span.button.chk.checkbox_false_part {
  background-position: -5px -48px;
}

.ztree li span.button.chk.checkbox_false_part_focus {
  background-position: -5px -68px;
}

.ztree li span.button.chk.checkbox_false_disable {
  background-position: -5px -89px;
}

.ztree li span.button.chk.checkbox_true_full {
  background-position: -26px -5px;
}

.ztree li span.button.chk.checkbox_true_full_focus {
  background-position: -26px -26px;
}

.ztree li span.button.chk.checkbox_true_part {
  background-position: -26px -48px;
}

.ztree li span.button.chk.checkbox_true_part_focus {
  background-position: -26px -68px;
}

.ztree li span.button.chk.checkbox_true_disable {
  background-position: -26px -89px;
}

.ztree li span.button.chk.radio_false_full {
  background-position: -47px -5px;
}

.ztree li span.button.chk.radio_false_full_focus {
  background-position: -47px -26px;
}

.ztree li span.button.chk.radio_false_part {
  background-position: -47px -47px;
}

.ztree li span.button.chk.radio_false_part_focus {
  background-position: -47px -68px;
}

.ztree li span.button.chk.radio_false_disable {
  background-position: -47px -89px;
}

.ztree li span.button.chk.radio_true_full {
  background-position: -68px -5px;
}

.ztree li span.button.chk.radio_true_full_focus {
  background-position: -68px -26px;
}

.ztree li span.button.chk.radio_true_part {
  background-position: -68px -47px;
}

.ztree li span.button.chk.radio_true_part_focus {
  background-position: -68px -68px;
}

.ztree li span.button.chk.radio_true_disable {
  background-position: -68px -89px;
}

.ztree li span.button.switch {
  width: 21px;
  height: 21px;
}

.ztree li span.button.root_open {
  background-position: -105px -63px;
}

.ztree li span.button.root_close {
  background-position: -126px -63px;
}

.ztree li span.button.roots_open {
  background-position: -105px 0;
}

.ztree li span.button.roots_close {
  background-position: -126px 0;
}

.ztree li span.button.center_open {
  background-position: -105px -21px;
}

.ztree li span.button.center_close {
  background-position: -126px -21px;
}

.ztree li span.button.bottom_open {
  background-position: -105px -42px;
}

.ztree li span.button.bottom_close {
  background-position: -126px -42px;
}

.ztree li span.button.noline_open {
  background-position: -105px -84px;
}

.ztree li span.button.noline_close {
  background-position: -126px -84px;
}

.ztree li span.button.roots_docu {
  background-position: -84px 0;
}

.ztree li span.button.center_docu {
  background-position: -84px -21px;
}

.ztree li span.button.bottom_docu {
  background-position: -84px -42px;
}

.ztree li span.button.ico_open {
  background-position: -147px -21px;
}

.ztree li span.button.ico_close {
  background-position: -147px 0;
}

.ztree li span.button.ico_docu {
  background-position: -147px -43px;
}

.ztree li span.button.edit {
  margin-left: 2px;
  margin-right: -1px;
  background-position: -189px -22px;
}

.ztree li span.button.edit:hover {
  background-position: -168px -22px;
}

.ztree li span.button.remove {
  margin-left: 2px;
  margin-right: -1px;
  background-position: -189px -43px;
}

.ztree li span.button.remove:hover {
  background-position: -168px -43px;
}

.ztree li span.button.add {
  background-position: -189px 0;
}

.ztree li span.button.add:hover {
  background-position: -168px 0;
}

.ztree li span.button.ico_loading {
  background: url(../img/loading.gif) no-repeat scroll 0 0 transparent !important;
}

ul.tmpTargetzTree {
  background-color: unset;
}

span.tmpzTreeMove_arrow {
  width: 21px;
  height: 21px;
  background-position: -168px -84px;
  background-image: url("../img/bootstrap.png") !important;
}

.swal2-popup {
  background-color: var(--bs-body-bg) !important;
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  background-color: var(--bs-body-bg) !important;
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  background-color: var(--bs-body-bg) !important;
}

.swal2-icon.swal2-success .swal2-success-fix {
  background-color: var(--bs-body-bg) !important;
}

.swal2-title {
  color: var(--bs-body-color) !important;
}

.swal2-html-container {
  color: var(--bs-secondary-color) !important;
}

.swal2-timer-progress-bar {
  background-color: var(--bs-secondary-bg) !important;
}

/*顶部工具条背景颜色*/
.w-e-bar {
  background-color: var(--bs-body-bg) !important;
}

.w-e-bar-item .disabled {
  color: var(--bs-tertiary-color) !important;
}

.w-e-bar-item .disabled svg {
  fill: var(--bs-tertiary-color) !important;
}

/*在编辑器中选中文字后的颜色*/
.w-e-hover-bar {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*这个是删除线-行内代码-上标-下表-清除格式*/
.w-e-bar-item-group .w-e-bar-item-menus-container {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*颜色下拉菜单*/
.w-e-drop-panel {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.w-e-panel-content-color li .color-block {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.w-e-panel-content-color li {
  border: var(--bs-border-width) solid var(--bs-body-bg) !important;
}

.w-e-panel-content-color li:hover {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*修改每个按钮的颜色*/
.w-e-bar-item button {
  color: var(--bs-body-color) !important;
}

/*鼠标滑过时的颜色*/
.w-e-bar-item button:hover {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08) !important;
}

/*工具条上的项目被激活时的背景色*/
.w-e-bar-item .active {
  background-color: var(--bs-secondary-bg) !important;
}

/*表格*/
.w-e-panel-content-table {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
}

/*表格里面每个td*/
.w-e-panel-content-table td {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*修改每个svg的言饿死*/
.w-e-bar svg {
  fill: var(--bs-body-color) !important;
}

/*修改分割线的颜色*/
.w-e-bar-divider {
  background-color: var(--bs-secondary-bg) !important;
}

/*背景色*/
.w-e-select-list {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*被选中的*/
.w-e-select-list ul .selected {
  background-color: var(--bs-secondary-bg) !important;
}

/*鼠标移过*/
.w-e-select-list ul li:hover {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08) !important;
}

.w-e-select-list ul li {
  color: var(--bs-body-color) !important;
}

.w-e-text-container [data-slate-editor] {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
}

/*链接、图片、视频*/
.w-e-modal {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}

/*链接、图片、视频里面的输入框*/
.w-e-modal input[type=number], .w-e-modal input[type=text], .w-e-modal textarea {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}

.w-e-text-container {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-top: none !important;
}

.w-e-toolbar {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*提示框保持和bootstrap风格主题一致*/
.w-e-menu-tooltip-v5:before {
  background-color: var(--bs-emphasis-color) !important;
  color: var(--bs-body-bg) !important;
  opacity: 0.9 !important;
}

/*编辑内容时表格外层包裹的的一个大容器*/
.w-e-text-container [data-slate-editor] [data-selected=true] {
  box-shadow: 0 0 0 2px var(--bs-border-color) !important;
}

/*表格的包裹虚线边框*/
.w-e-text-container [data-slate-editor] .table-container {
  border: 1px dashed var(--bs-border-color) !important;
}

/*编辑区域表格的表头*/
.w-e-text-container [data-slate-editor] table th {
  background-color: var(--bs-secondary-bg) !important;
}

/*编辑区域表格的td，th所有的边框*/
.w-e-text-container [data-slate-editor] table td,
.w-e-text-container [data-slate-editor] table th {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

/*代码区域的背景色*/
.w-e-text-container [data-slate-editor] pre > code {
  background-color: var(--bs-secondary-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  text-shadow: 0 1px var(--bs-body-color) !important;
  color: var(--bs-body-color) !important;
}

/*工具栏表情选择的配色*/
.w-e-panel-content-emotion li:hover {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08) !important;
}

/*模态框的按钮背景色*/
.w-e-modal button {
  background-color: var(--bs-tertiary-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}

/*placehoder提示信息的颜色*/
.w-e-max-length-info, .w-e-text-placeholder {
  color: var(--bs-secondary-color) !important;
}

.w-e-modal .btn-close {
  background: unset !important;
}

.w-e-text-container {
  background-color: var(--bs-body-bg) !important;
}

.w-e-textarea-divider hr {
  background-color: var(--bs-secondary-color) !important;
}

.os-scrollbar .os-scrollbar-handle {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.08) !important;
}

.os-scrollbar .os-scrollbar-handle:hover {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.18) !important;
}

.os-scrollbar .os-scrollbar-handle:active {
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.28) !important;
}

.tempus-dominus-widget.light {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
}

.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).old, .tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).new, .tempus-dominus-widget.light .date-container-years div:not(.no-highlight).old, .tempus-dominus-widget.light .date-container-years div:not(.no-highlight).new, .tempus-dominus-widget.light .date-container-months div:not(.no-highlight).old, .tempus-dominus-widget.light .date-container-months div:not(.no-highlight).new, .tempus-dominus-widget.light .date-container-days div:not(.no-highlight).old, .tempus-dominus-widget.light .date-container-days div:not(.no-highlight).new, .tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).old, .tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).new, .tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).old, .tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).new, .tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).old, .tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).new, .tempus-dominus-widget.light .time-container-second div:not(.no-highlight).old, .tempus-dominus-widget.light .time-container-second div:not(.no-highlight).new {
  color: var(--bs-secondary-color);
}

.tempus-dominus-widget.light .date-container-days .dow {
  color: var(--bs-secondary-color);
}

.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight):hover,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight):hover,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight):hover,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight):hover {
  background: var(--bs-secondary-bg);
}

.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).active,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight).active,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight).active,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).active,
.tempus-dominus-widget.light .date-container-days div.range-in:not(.no-highlight),
.tempus-dominus-widget.light .date-container-days div.range-end:not(.no-highlight),
.tempus-dominus-widget.light .date-container-days div.range-start:not(.no-highlight),
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight).active {
  background-color: #0d6efd;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.tempus-dominus-widget.light .toolbar div:hover {
  background: var(--bs-secondary-bg);
}

.bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
  outline: none !important;
}

.bsa-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: var(--bsa-sidebar-width);
  right: 0;
  height: var(--bsa-header-height);
  background-color: var(--bs-body-bg);
  border-bottom: var(--bs-border-width) solid var(--bs-border-color);
  z-index: 80;
  list-style: none;
  margin: 0;
  padding: 0 12px;
}
@media (max-width: 991.98px) {
  .bsa-header {
    left: 0;
    padding: 0;
  }
  .bsa-header .bsa-search-form-wrapper {
    display: none;
  }
  .bsa-header .bsa-search-form-wrapper.open {
    border-bottom: var(--bs-border-width) solid var(--bs-border-color);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: var(--bsa-header-height);
    padding: 0 12px;
    z-index: 10;
    display: flex;
    align-items: center;
    background-color: var(--bs-body-bg);
    width: 100%;
  }
  .bsa-header .bsa-search-form-wrapper.open .bsa-search-form {
    width: 100%;
  }
}
.bsa-header > li {
  height: inherit;
}
@media (max-width: 991.98px) {
  .bsa-header .dropdown {
    position: static !important;
  }
}
.bsa-header .dropdown-menu {
  border: 0;
  border-radius: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-secondary-color-rgb), 0.075);
  max-width: 360px;
}
@media (max-width: 991.98px) {
  .bsa-header .dropdown-menu {
    min-width: 100%;
  }
}
.bsa-header .dropdown-menu .card {
  border: 0;
  min-width: 300px;
  max-height: 280px;
}
@media (max-width: 991.98px) {
  .bsa-header .dropdown-menu .card {
    min-width: 100%;
  }
}
.bsa-header .dropdown-menu .card .card-body {
  overflow: hidden;
}
.bsa-header .dropdown-menu .dropdown-item {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 20px;
}
.bsa-header .bsa-sidebar-toggler {
  display: none;
}
@media (max-width: 991.98px) {
  .bsa-header .bsa-sidebar-toggler {
    display: block;
  }
}
.bsa-header .bsa-search-form-toggler {
  display: none;
}
@media (max-width: 991.98px) {
  .bsa-header .bsa-search-form-toggler {
    display: inline;
  }
}
.bsa-header .bsa-search-form {
  position: relative;
  width: 240px;
}
.bsa-header .bsa-search-form .form-control {
  padding-right: 44px;
  padding-left: 20px;
  border-radius: 30px;
}
.bsa-header .bsa-search-form .form-control:focus {
  box-shadow: none;
  border-color: var(--bs-border-color);
}
.bsa-header .bsa-search-form .bsa-search-close-btn,
.bsa-header .bsa-search-form .bsa-search-submit-btn {
  font-size: 18px;
  padding: 0;
  border: 0;
  height: inherit;
  width: 24px;
  background-color: transparent;
  color: var(--bs-body-color);
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
@media (max-width: 991.98px) {
  .bsa-header .bsa-search-form .bsa-search-submit-btn {
    display: none;
  }
}
.bsa-header .bsa-search-form .bsa-search-close-btn {
  display: none;
}
@media (max-width: 991.98px) {
  .bsa-header .bsa-search-form .bsa-search-close-btn {
    display: inline;
  }
}

.bsa-theme-switcher-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
}
.bsa-theme-switcher-wrapper input[type=checkbox] {
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 30px;
  font-size: 0.9rem;
  visibility: hidden;
}
.bsa-theme-switcher-wrapper input[type=checkbox]::after {
  position: absolute;
  background-color: #ccc;
  width: 30px;
  height: 30px;
  visibility: visible;
  content: " ";
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 50%;
}
.bsa-theme-switcher-wrapper input[type=checkbox]:checked::after {
  content: "✓";
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=light]::after {
  background-color: #fff;
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=light]:checked::after {
  color: var(--bs-body-color);
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=dark]::after {
  background-color: #212529;
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=indigo]::after {
  background-color: #6610f2;
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=green]::after {
  background-color: #198754;
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=blue]::after {
  background-color: #0d6efd;
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=yellow]::after {
  background-color: #ffc107;
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=orange]::after {
  background-color: #fd7e14;
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=teal]::after {
  background-color: #20c997;
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=cyan]::after {
  background-color: #0dcaf0;
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=pink]::after {
  background-color: #d63384;
}
.bsa-theme-switcher-wrapper input[type=checkbox][value=red]::after {
  background-color: #dc3545;
}

.bsa-header-item {
  cursor: pointer;
  background: inherit;
  font-size: 24px;
  padding: 0 20px;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-body-color);
}
@media (max-width: 991.98px) {
  .bsa-header-item {
    padding: 0 20px;
    font-size: 18px;
  }
}
@media (max-width: 767.98px) {
  .bsa-header-item {
    padding: 0 16px;
  }
}
@media (max-width: 575.98px) {
  .bsa-header-item {
    padding: 0 12px;
  }
}
@media (max-width: 341.98px) {
  .bsa-header-item {
    padding: 0 8px;
  }
}
.bsa-header-item .bsa-user-area {
  display: flex;
  align-items: center;
}
.bsa-header-item .bsa-user-area .bsa-user-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  user-select: none;
}
@media (max-width: 991.98px) {
  .bsa-header-item .bsa-user-area .bsa-user-avatar {
    height: 35px;
    width: 35px;
  }
}
@media (max-width: 341.98px) {
  .bsa-header-item .bsa-user-area .bsa-user-avatar {
    height: 30px;
    width: 30px;
  }
}
.bsa-header-item .bsa-user-area .bsa-user-details {
  margin-left: 8px;
  flex-shrink: 0;
  display: block;
  max-width: 150px;
}
@media (max-width: 1199.98px) {
  .bsa-header-item .bsa-user-area .bsa-user-details {
    display: none;
  }
}

.bsa-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--bsa-sidebar-width);
  z-index: 100;
  transform: translateX(0);
  background-color: var(--bs-body-bg);
  border-right: var(--bs-border-width) solid var(--bs-border-color);
  will-change: transform;
}
@media (max-width: 991.98px) {
  .bsa-sidebar {
    transform: translateX(-101%);
    box-shadow: none;
  }
}
.bsa-sidebar .bsa-sidebar-header {
  width: 100%;
  height: var(--bsa-header-height);
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0 15px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  background-clip: padding-box;
  border-bottom: var(--bs-border-width) solid var(--bs-border-color);
}
.bsa-sidebar .bsa-sidebar-header .bsa-logo-icon {
  max-width: 36px;
  max-height: 36px;
}
.bsa-sidebar .bsa-sidebar-header .bsa-logo-text {
  margin-bottom: 0;
  letter-spacing: 1px;
  color: var(--bs-body-color);
}
.bsa-sidebar .bsa-sidebar-body {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  inset: var(--bsa-header-height) 0 0 0;
}
.bsa-sidebar.open {
  transform: translateX(0);
}

.bsa-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}
.bsa-menu li + li {
  padding-top: 5px;
}
.bsa-menu li:first-child {
  padding-top: 5px;
}
.bsa-menu li:last-child {
  padding-bottom: 5px;
}
.bsa-menu li a {
  position: relative;
  padding-left: 16px;
  padding-right: 24px;
  color: var(--bs-body-color);
  text-decoration: none;
  height: 40px;
  line-height: 40px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  cursor: pointer;
}
.bsa-menu li a > i {
  margin-right: 10px;
}
.bsa-menu li a + ul {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  display: none;
}
.bsa-menu li a + ul a {
  padding-left: calc(16px + 1rem + 10px);
}
.bsa-menu li a + ul a + ul a {
  padding-left: calc(16px + 2rem + 10px);
}
.bsa-menu li a + ul a + ul a + ul a {
  padding-left: calc(16px + 3rem + 10px);
}
.bsa-menu li a + ul a + ul a + ul a + ul a {
  padding-left: calc(16px + 4rem + 10px);
}
.bsa-menu li a + ul a + ul a + ul a + ul a + ul a {
  padding-left: calc(16px + 5rem + 10px);
}
.bsa-menu li a.active, .bsa-menu li a:active, .bsa-menu li a:focus, .bsa-menu li a:hover {
  color: #0d6efd;
  background-color: rgba(13, 110, 253, 0.12);
}
.bsa-menu li a.has-children::after {
  font-family: bootstrap-icons, serif;
  content: "\f284";
  transition: transform 0.3s linear;
  transform: scale(0.5) rotate(0deg);
  position: absolute;
  right: 24px;
}
.bsa-menu li a.has-children.open {
  color: #0d6efd;
  background-color: rgba(13, 110, 253, 0.12);
}
.bsa-menu li a.has-children.open::after {
  transform: scale(0.5) rotate(-90deg);
}
.bsa-menu li a.has-children.open + ul {
  display: block;
}

.bsa-content {
  position: fixed;
  inset: var(--bsa-header-height) 0 var(--bsa-footer-height) var(--bsa-sidebar-width);
  overflow: hidden;
}
.bsa-content.fullscreen {
  inset: 0;
  z-index: 9999;
}
@media (max-width: 991.98px) {
  .bsa-content {
    left: 0;
  }
}

.bsa-footer {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  left: var(--bsa-sidebar-width);
  right: 0;
  bottom: 0;
  position: fixed;
  text-align: center;
  padding: 7px;
  font-size: 14px;
  border-top: var(--bs-border-width) solid var(--bs-border-color);
  z-index: 3;
  height: var(--bsa-footer-height);
  box-sizing: border-box;
}
@media (max-width: 991.98px) {
  .bsa-footer {
    left: 0;
  }
}

.bsa-preloader {
  position: fixed;
  z-index: 99999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #f4f6f9;
}

@media (max-width: 991.98px) {
  .bsa-mask {
    z-index: 90;
    position: fixed;
    inset: 0;
    transition: all 0.2s ease-out;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: move;
  }
}
.bsa-layout1 .bsa-layout1-left {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  z-index: 1020;
}
.bsa-layout1 .bsa-layout1-left .bsa-chevron-toggle {
  position: absolute;
  width: 16px;
  height: 60px;
  right: -16px;
  top: 40%;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--bs-dark-text-emphasis);
  background-color: var(--bs-dark-bg-subtle);
}
.bsa-layout1 .bsa-layout1-left .bsa-chevron-toggle::after {
  font-family: bootstrap-icons, serif;
  content: "\f284";
}
@media (max-width: 991.98px) {
  .bsa-layout1 .bsa-layout1-left .bsa-chevron-toggle::after {
    content: "\f285";
  }
}
.bsa-layout1 .bsa-layout1-left .bsa-chevron-toggle:hover {
  color: var(--bs-light-text-emphasis);
  background-color: var(--bs-light-bg-subtle);
}
@media (max-width: 991.98px) {
  .bsa-layout1 .bsa-layout1-left {
    transition: 0.3s transform;
    will-change: transform;
    transform: translateX(-101%);
  }
  .bsa-layout1 .bsa-layout1-left.open {
    transform: translateX(0) !important;
  }
  .bsa-layout1 .bsa-layout1-left.open .bsa-chevron-toggle::after {
    font-family: bootstrap-icons, serif;
    content: "\f284";
  }
}
.bsa-layout1 .bsa-layout1-right {
  margin-left: 244px;
}
@media (max-width: 991.98px) {
  .bsa-layout1 .bsa-layout1-right {
    margin-left: 0;
  }
}
.bsa-layout1.collapsed .bsa-layout1-left {
  transform: translateX(-101%);
}
.bsa-layout1.collapsed .bsa-layout1-right {
  margin-left: 0;
}
.bsa-layout1.collapsed .bsa-chevron-toggle::after {
  content: "\f285";
}

.bsa-layout2.left-collapsed .bsa-layout2-left {
  transform: translateX(-101%);
}
.bsa-layout2.left-collapsed .bsa-layout2-left .bsa-chevron-toggle::after {
  content: "\f285";
}
.bsa-layout2.left-collapsed .bsa-layout2-center {
  margin-left: 0;
}
.bsa-layout2.right-collapsed .bsa-layout2-right {
  transform: translateX(101%);
}
.bsa-layout2.right-collapsed .bsa-layout2-right .bsa-chevron-toggle::after {
  content: "\f284";
}
.bsa-layout2.right-collapsed .bsa-layout2-center {
  margin-right: 0;
}
.bsa-layout2 .bsa-layout2-left {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 240px;
  z-index: 1020;
}
@media (max-width: 991.98px) {
  .bsa-layout2 .bsa-layout2-left {
    transition: 0.3s transform;
    will-change: transform;
    transform: translateX(-101%);
  }
  .bsa-layout2 .bsa-layout2-left.left-open {
    transform: translateX(0) !important;
  }
  .bsa-layout2 .bsa-layout2-left.left-open .bsa-chevron-toggle::after {
    font-family: bootstrap-icons, serif;
    content: "\f284";
  }
}
.bsa-layout2 .bsa-layout2-left .bsa-chevron-toggle {
  position: absolute;
  width: 16px;
  height: 60px;
  right: -16px;
  top: 40%;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--bs-dark-text-emphasis);
  background-color: var(--bs-dark-bg-subtle);
}
.bsa-layout2 .bsa-layout2-left .bsa-chevron-toggle::after {
  font-family: bootstrap-icons, serif;
  content: "\f284";
}
@media (max-width: 991.98px) {
  .bsa-layout2 .bsa-layout2-left .bsa-chevron-toggle::after {
    content: "\f285";
  }
}
.bsa-layout2 .bsa-layout2-left .bsa-chevron-toggle:hover {
  color: var(--bs-light-text-emphasis);
  background-color: var(--bs-light-bg-subtle);
}
.bsa-layout2 .bsa-layout2-center {
  margin-left: 244px;
  margin-right: 244px;
}
@media (max-width: 991.98px) {
  .bsa-layout2 .bsa-layout2-center {
    margin-left: 0;
    margin-right: 0;
  }
}
.bsa-layout2 .bsa-layout2-right {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 240px;
  z-index: 1022;
}
@media (max-width: 991.98px) {
  .bsa-layout2 .bsa-layout2-right {
    transition: 0.3s transform;
    will-change: transform;
    transform: translateX(101%);
  }
  .bsa-layout2 .bsa-layout2-right.right-open {
    transform: translateX(0) !important;
  }
  .bsa-layout2 .bsa-layout2-right.right-open .bsa-chevron-toggle::after {
    font-family: bootstrap-icons, serif;
    content: "\f285";
  }
}
.bsa-layout2 .bsa-layout2-right .bsa-chevron-toggle {
  position: absolute;
  width: 16px;
  height: 60px;
  left: -16px;
  top: 40%;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--bs-dark-text-emphasis);
  background-color: var(--bs-dark-bg-subtle);
}
.bsa-layout2 .bsa-layout2-right .bsa-chevron-toggle::after {
  font-family: bootstrap-icons, serif;
  content: "\f285";
}
@media (max-width: 991.98px) {
  .bsa-layout2 .bsa-layout2-right .bsa-chevron-toggle::after {
    content: "\f284";
  }
}
.bsa-layout2 .bsa-layout2-right .bsa-chevron-toggle:hover {
  color: var(--bs-light-text-emphasis);
  background-color: var(--bs-light-bg-subtle);
}

.bsa-layout3 .bsa-layout3-right {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 240px;
  z-index: 1020;
}
.bsa-layout3 .bsa-layout3-right .bsa-chevron-toggle {
  position: absolute;
  width: 16px;
  height: 60px;
  left: -16px;
  top: 40%;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--bs-dark-text-emphasis);
  background-color: var(--bs-dark-bg-subtle);
}
.bsa-layout3 .bsa-layout3-right .bsa-chevron-toggle::after {
  font-family: bootstrap-icons, serif;
  content: "\f285";
}
@media (max-width: 991.98px) {
  .bsa-layout3 .bsa-layout3-right .bsa-chevron-toggle::after {
    content: "\f284";
  }
}
.bsa-layout3 .bsa-layout3-right .bsa-chevron-toggle:hover {
  color: var(--bs-light-text-emphasis);
  background-color: var(--bs-light-bg-subtle);
}
@media (max-width: 991.98px) {
  .bsa-layout3 .bsa-layout3-right {
    transition: 0.3s transform;
    will-change: transform;
    transform: translateX(101%);
  }
  .bsa-layout3 .bsa-layout3-right.open {
    transform: translateX(0) !important;
  }
  .bsa-layout3 .bsa-layout3-right.open .bsa-chevron-toggle::after {
    font-family: bootstrap-icons, serif;
    content: "\f285";
  }
}
.bsa-layout3 .bsa-layout3-left {
  margin-right: 244px;
}
@media (max-width: 991.98px) {
  .bsa-layout3 .bsa-layout3-left {
    margin-right: 0;
  }
}
.bsa-layout3.collapsed .bsa-layout3-left {
  margin-right: 0;
}
.bsa-layout3.collapsed .bsa-layout3-right {
  transform: translateX(101%);
}
.bsa-layout3.collapsed .bsa-chevron-toggle::after {
  content: "\f284";
}

.bsa-back-to-top {
  display: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 26px;
  position: fixed;
  border-radius: 10px;
  bottom: 20px;
  right: 12px;
  color: var(--bs-light-text-emphasis);
  background-color: var(--bs-light-bg-subtle);
  z-index: 1028;
}
@media (max-width: 991.98px) {
  .bsa-back-to-top {
    z-index: 1001;
  }
}
.bsa-back-to-top:hover {
  color: var(--bs-dark-text-emphasis);
  background-color: var(--bs-dark-bg-subtle);
  transition: all 0.5s;
}

.bsa-error-code {
  font-size: 7rem;
  font-weight: 700;
  text-shadow: 4px 4px 0 var(--bs-light-bg-subtle), 6px 6px 0 var(--bs-secondary-color);
  color: var(--bs-secondary-color);
  word-break: break-all;
  text-align: center;
}

.bsa-error-text {
  border-top: 5px solid var(--bs-secondary-color);
  margin-bottom: 30px;
  padding-top: 20px;
  text-align: center;
  color: var(--bs-secondary-color);
}

.bsa-gallery-grid {
  column-count: 4;
  column-gap: 12px;
  column-fill: balance;
}
@media (max-width: 767.98px) {
  .bsa-gallery-grid {
    column-count: 2;
  }
}
@media (max-width: 575.98px) {
  .bsa-gallery-grid {
    column-count: 1;
  }
}
.bsa-gallery-grid .bsa-gallery-item {
  padding-bottom: 12px;
  break-inside: avoid;
}
.bsa-gallery-grid .card {
  transition: all 0.2s;
}
@media (min-width: 992px) {
  .bsa-gallery-grid .card:hover {
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3) !important;
  }
}

.bsa-timeline1-wrapper {
  position: relative;
  /* 内容周围的容器 */
  /* 将容器放在左侧 */
  /* 将容器放置在右侧 */
}
.bsa-timeline1-wrapper::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: rgba(var(--bs-tertiary-color-rgb), 0.2);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}
@media (max-width: 767.98px) {
  .bsa-timeline1-wrapper::after {
    left: 29px;
  }
}
.bsa-timeline1-wrapper .bsa-timeline1 {
  position: relative;
  background-color: inherit;
  width: 50%;
}
@media (max-width: 767.98px) {
  .bsa-timeline1-wrapper .bsa-timeline1 {
    width: 100%;
    padding-left: 70px;
    padding-right: 0;
  }
  .bsa-timeline1-wrapper .bsa-timeline1::before {
    left: 61px;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent var(--bs-tertiary-color) transparent transparent;
  }
}
.bsa-timeline1-wrapper .bsa-timeline1::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  right: -8px;
  background-color: var(--bs-dark-bg-subtle);
  border: 5px solid var(--bs-dark-border-subtle);
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}
.bsa-timeline1-wrapper .bsa-timeline1-left {
  padding: 0 40px 20px 0;
  left: 0;
}
.bsa-timeline1-wrapper .bsa-timeline1-left::before {
  content: " ";
  position: absolute;
  top: 17px;
  z-index: 1;
  right: 30px;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgba(var(--bs-tertiary-color-rgb), 0.2);
}
@media (max-width: 767.98px) {
  .bsa-timeline1-wrapper .bsa-timeline1-left::before {
    right: auto;
  }
}
@media (max-width: 767.98px) {
  .bsa-timeline1-wrapper .bsa-timeline1-left::after {
    left: 18px;
  }
}
.bsa-timeline1-wrapper .bsa-timeline1-right {
  padding: 0 0 20px 40px;
  left: 50%;
}
@media (max-width: 767.98px) {
  .bsa-timeline1-wrapper .bsa-timeline1-right {
    left: 0;
  }
}
.bsa-timeline1-wrapper .bsa-timeline1-right::before {
  content: " ";
  position: absolute;
  top: 17px;
  z-index: 1;
  left: 31px;
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--bs-dark-bg-subtle) transparent transparent;
}
.bsa-timeline1-wrapper .bsa-timeline1-right::after {
  left: -12px;
}
@media (max-width: 767.98px) {
  .bsa-timeline1-wrapper .bsa-timeline1-right::after {
    left: 18px;
  }
}

ul.bsa-timeline2 {
  list-style-type: none;
  position: relative;
}
ul.bsa-timeline2::before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.bsa-timeline2 > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.bsa-timeline2 > li::before {
  content: " ";
  background: #fff;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.bsa-has-msg {
  animation: tada 1s infinite;
}

.bsa-cursor-pointer {
  cursor: pointer !important;
}

.bsa-cursor-default {
  cursor: default !important;
}

.bsa-ellipsis-1 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
  overflow: hidden !important;
  word-break: break-all !important;
}

.bsa-ellipsis-2 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
  overflow: hidden !important;
  word-break: break-all !important;
}

.bsa-ellipsis-3 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
  overflow: hidden !important;
  word-break: break-all !important;
}

.bsa-ellipsis-4 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 4 !important;
  overflow: hidden !important;
  word-break: break-all !important;
}

.bsa-ellipsis-5 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 5 !important;
  overflow: hidden !important;
  word-break: break-all !important;
}

.bsa-fs-12 {
  font-size: 12px !important;
}

.bsa-fs-13 {
  font-size: 13px !important;
}

.bsa-fs-14 {
  font-size: 14px !important;
}

.bsa-fs-15 {
  font-size: 15px !important;
}

.bsa-fs-16 {
  font-size: 16px !important;
}

.bsa-fs-17 {
  font-size: 17px !important;
}

.bsa-fs-18 {
  font-size: 18px !important;
}

.bsa-fs-19 {
  font-size: 19px !important;
}

.bsa-fs-20 {
  font-size: 20px !important;
}

.bsa-fs-25 {
  font-size: 25px !important;
}

.bsa-fs-30 {
  font-size: 30px !important;
}

.bsa-fs-35 {
  font-size: 35px !important;
}

.bsa-fs-40 {
  font-size: 40px !important;
}

.bsa-fs-45 {
  font-size: 45px !important;
}

.bsa-fs-50 {
  font-size: 50px !important;
}

.bsa-fs-55 {
  font-size: 55px !important;
}

.bsa-fs-60 {
  font-size: 60px !important;
}

.bsa-opacity-10 {
  opacity: 0.1 !important;
}

.bsa-opacity-20 {
  opacity: 0.2 !important;
}

.bsa-opacity-30 {
  opacity: 0.3 !important;
}

.bsa-opacity-40 {
  opacity: 0.4 !important;
}

.bsa-opacity-50 {
  opacity: 0.5 !important;
}

.bsa-opacity-60 {
  opacity: 0.6 !important;
}

.bsa-opacity-70 {
  opacity: 0.7 !important;
}

.bsa-opacity-80 {
  opacity: 0.8 !important;
}

.bsa-opacity-90 {
  opacity: 0.9 !important;
}

.bsa-radius-10 {
  border-radius: 10px !important;
}

.bsa-radius-15 {
  border-radius: 15px !important;
}

.bsa-radius-20 {
  border-radius: 20px !important;
}

.bsa-radius-25 {
  border-radius: 25px !important;
}

.bsa-radius-30 {
  border-radius: 30px !important;
}

.bsa-w-5 {
  width: 5px !important;
}

.bsa-w-10 {
  width: 10px !important;
}

.bsa-w-15 {
  width: 15px !important;
}

.bsa-w-20 {
  width: 20px !important;
}

.bsa-w-25 {
  width: 25px !important;
}

.bsa-w-30 {
  width: 30px !important;
}

.bsa-w-35 {
  width: 35px !important;
}

.bsa-w-40 {
  width: 40px !important;
}

.bsa-w-45 {
  width: 45px !important;
}

.bsa-w-50 {
  width: 50px !important;
}

.bsa-w-55 {
  width: 55px !important;
}

.bsa-w-60 {
  width: 60px !important;
}

.bsa-w-65 {
  width: 65px !important;
}

.bsa-w-70 {
  width: 70px !important;
}

.bsa-w-75 {
  width: 75px !important;
}

.bsa-w-80 {
  width: 80px !important;
}

.bsa-w-85 {
  width: 85px !important;
}

.bsa-w-90 {
  width: 90px !important;
}

.bsa-w-95 {
  width: 95px !important;
}

.bsa-w-100 {
  width: 100px !important;
}

.bsa-h-5 {
  height: 5px !important;
}

.bsa-h-10 {
  height: 10px !important;
}

.bsa-h-15 {
  height: 15px !important;
}

.bsa-h-20 {
  height: 20px !important;
}

.bsa-h-25 {
  height: 25px !important;
}

.bsa-h-30 {
  height: 30px !important;
}

.bsa-h-35 {
  height: 35px !important;
}

.bsa-h-40 {
  height: 40px !important;
}

.bsa-h-45 {
  height: 45px !important;
}

.bsa-h-50 {
  height: 50px !important;
}

.bsa-h-55 {
  height: 55px !important;
}

.bsa-h-60 {
  height: 60px !important;
}

.bsa-h-65 {
  height: 65px !important;
}

.bsa-h-70 {
  height: 70px !important;
}

.bsa-h-75 {
  height: 75px !important;
}

.bsa-h-80 {
  height: 80px !important;
}

.bsa-h-85 {
  height: 85px !important;
}

.bsa-h-90 {
  height: 90px !important;
}

.bsa-h-95 {
  height: 95px !important;
}

.bsa-h-100 {
  height: 100px !important;
}

.bsa-wh-5 {
  width: 5px !important;
  height: 5px !important;
}

.bsa-wh-10 {
  width: 10px !important;
  height: 10px !important;
}

.bsa-wh-15 {
  width: 15px !important;
  height: 15px !important;
}

.bsa-wh-20 {
  width: 20px !important;
  height: 20px !important;
}

.bsa-wh-25 {
  width: 25px !important;
  height: 25px !important;
}

.bsa-wh-30 {
  width: 30px !important;
  height: 30px !important;
}

.bsa-wh-35 {
  width: 35px !important;
  height: 35px !important;
}

.bsa-wh-40 {
  width: 40px !important;
  height: 40px !important;
}

.bsa-wh-45 {
  width: 45px !important;
  height: 45px !important;
}

.bsa-wh-50 {
  width: 50px !important;
  height: 50px !important;
}

.bsa-wh-55 {
  width: 55px !important;
  height: 55px !important;
}

.bsa-wh-60 {
  width: 60px !important;
  height: 60px !important;
}

.bsa-wh-65 {
  width: 65px !important;
  height: 65px !important;
}

.bsa-wh-70 {
  width: 70px !important;
  height: 70px !important;
}

.bsa-wh-75 {
  width: 75px !important;
  height: 75px !important;
}

.bsa-wh-80 {
  width: 80px !important;
  height: 80px !important;
}

.bsa-wh-85 {
  width: 85px !important;
  height: 85px !important;
}

.bsa-wh-90 {
  width: 90px !important;
  height: 90px !important;
}

.bsa-wh-95 {
  width: 95px !important;
  height: 95px !important;
}

.bsa-wh-100 {
  width: 100px !important;
  height: 100px !important;
}