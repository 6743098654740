@for $i from 12 through 20 {
  .bsa-fs-#{$i} {
    font-size: $i * 1px !important;
  }
}

$fs-start: 25;
$fs-end: 60;

@while $fs-start <= $fs-end {
  .bsa-fs-#{$fs-start} {
    font-size: $fs-start * 1px !important;
  }

  $fs-start: $fs-start + 5;
}
