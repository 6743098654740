@use "sass:string";

@for $i from 1 through  20 {
  .bsa-w-#{$i * 5} {
    width: string.unquote($i * 5 + "px") !important;
  }
}


@for $i from 1 through  20 {
  .bsa-h-#{$i * 5} {
    height: string.unquote($i * 5 + "px") !important;
  }
}

@for $i from 1 through  20 {
  .bsa-wh-#{$i * 5} {
    width: string.unquote($i * 5 + "px") !important;
    height: string.unquote($i * 5 + "px") !important;
  }
}
