@mixin cyan-root-vars {

  @include color-mode("cyan", true) {

    // scss-docs-start root-dark-mode-vars
    --#{$prefix}body-color: #{$body-color-cyan};
    --#{$prefix}body-color-rgb: #{to-rgb($body-color-cyan)};
    --#{$prefix}body-bg: #{$body-bg-cyan};
    --#{$prefix}body-bg-rgb: #{to-rgb($body-bg-cyan)};

    --#{$prefix}emphasis-color: #{$body-emphasis-color-cyan};
    --#{$prefix}emphasis-color-rgb: #{to-rgb($body-emphasis-color-cyan)};

    --#{$prefix}secondary-color: #{$body-secondary-color-cyan};
    --#{$prefix}secondary-color-rgb: #{to-rgb($body-secondary-color-cyan)};
    --#{$prefix}secondary-bg: #{$body-secondary-bg-cyan};
    --#{$prefix}secondary-bg-rgb: #{to-rgb($body-secondary-bg-cyan)};

    --#{$prefix}tertiary-color: #{$body-tertiary-color-cyan};
    --#{$prefix}tertiary-color-rgb: #{to-rgb($body-tertiary-color-cyan)};
    --#{$prefix}tertiary-bg: #{$body-tertiary-bg-cyan};
    --#{$prefix}tertiary-bg-rgb: #{to-rgb($body-tertiary-bg-cyan)};

    @each $color, $value in $theme-colors-text-cyan {
      --#{$prefix}#{$color}-text-emphasis: #{$value};
    }

    @each $color, $value in $theme-colors-bg-subtle-cyan {
      --#{$prefix}#{$color}-bg-subtle: #{$value};
    }

    @each $color, $value in $theme-colors-border-subtle-cyan {
      --#{$prefix}#{$color}-border-subtle: #{$value};
    }

    --#{$prefix}heading-color: #{$headings-color-cyan};

    --#{$prefix}link-color: #{$link-color-cyan};
    --#{$prefix}link-hover-color: #{$link-hover-color-cyan};
    --#{$prefix}link-color-rgb: #{to-rgb($link-color-cyan)};
    --#{$prefix}link-hover-color-rgb: #{to-rgb($link-hover-color-cyan)};

    --#{$prefix}code-color: #{$code-color-cyan};

    --#{$prefix}border-color: #{$border-color-cyan};
    --#{$prefix}border-color-translucent: #{$border-color-translucent-cyan};

    --#{$prefix}form-valid-color: #{$form-valid-color-cyan};
    --#{$prefix}form-valid-border-color: #{$form-valid-border-color-cyan};
    --#{$prefix}form-invalid-color: #{$form-invalid-color-cyan};
    --#{$prefix}form-invalid-border-color: #{$form-invalid-border-color-cyan};
    // scss-docs-end root-dark-mode-vars
  }

}
