.icons-selector.fip-bootstrap .selector-button {
  background-color: var(--bs-tertiary-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  background-image: none !important;
}

.icons-selector.fip-bootstrap .selected-icon {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-right: none !important;
}

.icons-selector.fip-bootstrap .selected-icon i {
  color: var(--bs-body-color) !important;
}

.icons-selector.fip-bootstrap span.current-icon,
.icons-selector.fip-bootstrap span.current-icon:hover {
  color: var(--bs-light-text-emphasis) !important;
  // background-color: var(--bs-light-bg-subtle) !important;
  background-color: rgba(var(--bs-tertiary-color-rgb),.1)!important;
  border: var(--bs-border-width) solid var(--bs-light-border-subtle) !important;
}

.icons-selector.fip-bootstrap .selector-popup {
  background-color: var(--bs-body-bg) !important;
  color: var(--bs-body-color) !important;
}

.icons-selector.fip-bootstrap .selector-category select,
.icons-selector.fip-bootstrap .selector-search input[type=text] {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  color: var(--bs-body-color) !important;
}

// 阴影直接给删掉
.icons-selector.fip-bootstrap .fip-box:hover {
  box-shadow: none !important;
}

// 阴影直接给删掉
.icons-selector.fip-bootstrap .selector-category select:focus, .icons-selector.fip-bootstrap .selector-search input[type=text]:focus {
  box-shadow: none !important;
}


.icons-selector.fip-bootstrap .selector-search input[type=text]::-webkit-input-placeholder {
  color: var(--bs-body-color) !important;
}

.icons-selector.fip-bootstrap .selector-search i {
  color: var(--bs-body-color) !important;
}

.icons-selector.fip-bootstrap .fip-icons-container {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.icons-selector.fip-bootstrap .fip-box {
  background-color: var(--bs-body-bg) !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.icons-selector.fip-bootstrap .fip-box:hover {
  background-color: var(--bs-body-bg) !important;
}

.icons-selector.fip-bootstrap .selector-button:hover {
  background-color: var(--bs-body-bg) !important;
}

.icons-selector.fip-bootstrap .selector-footer,
.icons-selector.fip-bootstrap .selector-footer i {
  color: var(--bs-body-color) !important;
}

// fix:白色主题下当前选中图标看不见的问题
.icons-selector.fip-bootstrap span.current-icon:hover i, .icons-selector.fip-bootstrap span.current-icon i {
  color: var(--bs-light-text-emphasis) !important;
}
