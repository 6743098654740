.bsa-error-code {
  font-size: 7rem;
  font-weight: 700;
  text-shadow: 4px 4px 0 var(--bs-light-bg-subtle), 6px 6px 0 var(--bs-secondary-color);
  color: var(--bs-secondary-color);
  word-break: break-all;
  text-align: center;
}

.bsa-error-text{
  border-top: 5px solid var(--bs-secondary-color);
  margin-bottom: 30px;
  padding-top: 20px;
  text-align: center;
  color: var(--bs-secondary-color);
}
